<template>
  <div class="container" style="margin-top:70px;">
    <div style="display:flex;">
      <back-button />
      <h5 style="margin:auto;">Saving Goal - {{this.userName}}</h5>
    </div>
    <hr>

    <form @submit.prevent="handleFormSubmission">
        <div class="form-group">
          <label for="savingGoal" class="required">Saving Goal</label>
          <input name="savingGoal" type="text" class="form-control" v-model="savingGoal"  @change="validateSavingGoal()"/>
          <ul class="error-feedback" v-if="savingGoalErrors.length > 0" v-html="savingGoalErrors"></ul>
        </div>

      <div class="form-group">
        <button class="btn btn-primary btn-block" :disabled="loading">
                  <span
                      v-show="loading"
                      class="spinner-border spinner-border-sm"
                  ></span>
                  Update
        </button>
      </div>
    </form>
  </div>

</template>

<script>



export default {
  name: 'SavingGoal',
  components: {

  },
  data(){


    return {
      userId:null,
      userInfo:{},
      userName:'',
      isAdmin:'0',
      successful: false,
      loading: false,
      message: "",
      savingGoal:'0',
      savingGoalErrors:'',
      hasValidationError: false,
      formData:{}
    };
  },
  computed:{

  },
  methods:{
    getUserSavingGoal: function(){
      this.axios
          .post(this.$hostApi,{task:'getUser', userId:this.userId},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            this.userName = response.data.FULL_NAME;
            this.savingGoal = response.data.SAVING_GOAL;

          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
    },

    handleFormSubmission(){
      this.$root.playClickSound();

      this.message = "";
      this.successful = false;
      this.loading = true;
      this.hasValidationError = false;

      this.validateSavingGoal();
      if(this.hasValidationError){
        this.loading = false;
        return;
      }

      this.formData = {
        'savingGoal':this.savingGoal,
        'userId':this.userId
      };


      this.axios
          .post(this.$hostApi,{task:'updateSavingGoal', data:this.formData},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            this.message = response;
            this.successful = true;
            this.loading = false;

            this.$root.refreshLocalFamilyUsers().then(res => this.$router.go(-1));

          })
          .catch(error => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            this.successful = false;
            this.loading = false;
          });

    },
    validateSavingGoal(){
      this.savingGoalErrors = this.$root.validateLength(this.savingGoal,0,10);
      if(isNaN(this.savingGoal)) this.savingGoalErrors += "<li>value must be a number.</li>";
      if(this.savingGoalErrors.length > 0){
        this.hasValidationError = true;
      }
    },
  },
  created() {
    this.userId = this.$route.query.userid;

  },
  mounted() {
    document.title = "Update Saving Goal";
    this.getUserSavingGoal();
  },

}
</script>

<style scoped>
.form-group{
  margin:15px 0;
}

.error-feedback{
  font-size:10px;
  color:red;

}

.small, small {
  font-size: 80% !important;
  font-weight: 400;
}

.required:after {
  content: " *";
  color: red;
}

</style>
